@import "_variables.scss";

body.nb-theme-material-light {
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  a.btn {
    text-decoration: none;
  }

  h2,
  .h2 {
    color: $primary;
    font-size: 1.8rem;
    font-weight: normal;
  }

  h3,
  .h3 {
    color: $primary;
    font-size: 1.6rem;
    font-weight: normal;
  }

  h4,
  .h4 {
    font-weight: normal;
  }

  .btn {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(0, 0, 0, 0.15) 45%,
      rgba(0, 0, 0, 0.15) 65%,
      rgba(255, 255, 255, 0.3)
    );
    background-repeat: repeat-x;
    font-weight: bold;
    padding: 0.28rem 0.9rem;
  }

  .btn + g-button {
    margin-left: 1rem;
  }

  g-button + g-button,
  g-button + .btn {
    margin-left: 0.9rem;
  }

  .btn.btn-sm {
    padding: 2px 4px;
  }
  .btn + .btn,
  .btn-group + .btn-group {
    margin-left: 0.9rem;
  }

  .btn-group .btn + .btn {
    margin-left: 0;
  }

  .btn-circle {
    border-radius: 50%;
    padding: 5px 8px;
  }

  .btn-outline-primary:hover {
    color: #fff !important;
  }

  a.btn {
    color: $white;
  }

  a.btn:hover {
    color: $white;
  }

  .table th {
    text-align: center;
  }

  .link {
    cursor: pointer;
  }

  .bold {
    font-weight: bold;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .dropdown-item {
    cursor: pointer;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    margin-top: 0.6rem;
  }

  @media only screen and (max-width: 480px) {
    hr {
      margin: 0.5rem 0;
    }

    h2,
    .h2 {
      font-size: 1.5rem;
    }
    h3,
    .h3 {
      font-size: 1.3rem;
    }
  }
}
