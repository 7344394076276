@import "./themes";

@mixin nb-overrides() {
  nb-layout .layout .layout-container .content .columns nb-layout-column {
    line-height: 1rem;
    padding: 1rem;
  }

  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }

  nb-layout-header nav {
    padding: 6px;
    height: 50px;
  }

  nb-layout-header.fixed ~ .layout-container {
    padding-top: 50px;
  }

  nb-layout .layout .layout-container .content nb-layout-footer.fixed nav.fixed {
    padding: 0.3rem;
    padding-left: 16px;
  }

  nb-layout .layout .layout-container nb-sidebar.fixed,
  nb-layout .layout .layout-container nb-sidebar .main-container-fixed {
    top: 52px;
    padding-top: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 0.9rem !important;
    height: 0.9rem !important;
  }

  .table {
    color: nb-theme(text-basic-color) !important;
  }

  .nb-theme-material-light nb-layout .layout .layout-container .content nb-layout-footer nav {
    padding: 1rem;
  }

  .nb-theme-material-light nb-layout.window-mode .scrollable-container {
    max-width: 100%;
    margin: 0 auto;
  }
}
