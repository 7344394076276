// For Bootstrap.//
.rtl {
  direction: rtl;

  .filter label {
    text-align: right;
  }

  h2,
  .h2 {
    text-align: right;
  }

  h3,
  .h3 {
    text-align: right;
  }

  label {
    text-align: right;
  }

  .btn + .btn {
    margin-right: 16px;
  }

  tr > td {
    text-align: right;
  }

  .col {
    text-align: right;
  }
}

// From PrimeNg.//
.rtl {
  .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item .ui-chkbox {
    margin: 0 0 0 0.5em;
  }
  .ui-dropdown-panel .ui-dropdown-item {
    text-align: right;
  }
}
