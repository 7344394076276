/*variables */
$navy: #001f3f;
$blue: #0064c9;
$aqua: #7fdbff;
$teal: #39cccc;
$olive: #3d9970;
$green: #2ecc40;
$lime: #01ff70;
$yellow: #ffdc00;
$orange: #ff851b;
$red: #ff4136;
$fuchsia: #f012be;
$purple: #b10dc9;
$maroon: #85144b;
$white: #ffffff;
$silver: #dddddd;
$gray: #aaaaaa;
$black: #111111;

$primary: #0064c9;
$secondary: $purple;
