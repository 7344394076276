@charset "UTF-8";
@import "_variables.scss";

.dialog-lg {
  max-width: 1200px;
  width: 100%;
}

.dialog-md {
  max-width: 900px;
  width: 100%;
}

.dialog-sm {
  max-width: 600px;
  width: 100%;
}

.p-datatable {
  font-size: 0.86rem;
}

.p-datatable .p-datatable-header {
  padding: 0.6rem;
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f2f2f2;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #007bff;
  color: #ffffff;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.8rem;
  color: #212529;
  background: #ffffff;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.6rem;
}

.p-dialog {
  font-size: 0.85rem;
}

.p-dialog .p-dialog-header {
  padding: 0.5rem;
  background-color: #ddf;
  // background-image: linear-gradient(
  //   to bottom,
  //   rgba(255, 255, 255, 0.3),
  //   rgba(200, 200, 255, 0.55) 45%,
  //   rgba(200, 200, 255, 0.55) 65%,
  //   rgba(255, 255, 255, 0.3)
  // );
  // background-repeat: repeat-x;
}

.p-dialog .p-dialog-footer {
  padding: 0.5rem;
}

.p-panel .p-panel-header {
  font-size: 1.2rem;
}

.p-selectbutton .p-button.p-highlight {
  background: #007bff;
  border-color: #007bff;
  color: #ffffff;
}
