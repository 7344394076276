@import "_variables.scss";

input.ng-touched.ng-invalid,
textarea.ng-touched.ng-invalid,
select.ng-touched.ng-invalid,
p-calendar.ng-touched.ng-invalid .p-calendar,
p-dropdown.ng-touched.ng-invalid .p-dropdown,
p-multiselect.ng-touched.ng-invalid .p-multiselect {
  border: 1px solid $red;
}

form.ng-touched.ng-invalid {
  border: none;
}

.error-container {
  padding: 5px;
}

.error {
  color: $red;
  padding: 5px;
}
