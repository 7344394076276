@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700,800&display=swap");

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "@nebular/theme/styles/globals";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";

@import "./material/angular-material";

@import "./layout";
@import "./overrides";
@import "./material/material-overrides";

// @import "./project/material-custom";

// install the framework and custom global styles
@include nb-install() {
  @include angular-material();

  // framework global styles
  @include nb-theme-global();

  @include ngx-layout();

  @include nb-overrides();
  @include material-overrides();
}

// @import "~@fortawesome/fontawesome-free/css/all.css";

@import "scss/loader.scss";
@import "scss/forms.scss";
@import "scss/bootstrap-custom.scss";
// @import "scss/bootstrap-float-label.scss";
@import "scss/primeng-custom.scss";
@import "scss/global.scss";
@import "scss/rtl.scss";
@import "scss/colors.scss";
