/*Loaders */
.loader {
  position: relative;
}

.loader:before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ccc;
  opacity: 0.6;
}

.loader:after {
  border-right: 4px solid #2E91BD;
  border-top: 4px solid #2E91BD;
  border-left: 4px solid #1584de;
  border-bottom: 4px solid #bbbbeb;
  z-index: 2;
  content: "";
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -10px;
  -webkit-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
  border-radius: 100%;
  -webkit-animation: standard 0.75s infinite linear;
  animation: standard 0.75s infinite linear;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.loader.no-overlay:before {
  content: none;
  display: none;
}

@-webkit-keyframes standard {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes standard {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Loader ends here. */
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/*variables */
input.ng-touched.ng-invalid,
textarea.ng-touched.ng-invalid,
select.ng-touched.ng-invalid,
p-calendar.ng-touched.ng-invalid .p-calendar,
p-dropdown.ng-touched.ng-invalid .p-dropdown,
p-multiselect.ng-touched.ng-invalid .p-multiselect {
  border: 1px solid #ff4136;
}

form.ng-touched.ng-invalid {
  border: none;
}

.error-container {
  padding: 5px;
}

.error {
  color: #ff4136;
  padding: 5px;
}

/*variables */
body.nb-theme-material-light a {
  text-decoration: none;
}
body.nb-theme-material-light a:hover {
  text-decoration: underline;
}
body.nb-theme-material-light a.btn {
  text-decoration: none;
}
body.nb-theme-material-light h2,
body.nb-theme-material-light .h2 {
  color: #0064c9;
  font-size: 1.8rem;
  font-weight: normal;
}
body.nb-theme-material-light h3,
body.nb-theme-material-light .h3 {
  color: #0064c9;
  font-size: 1.6rem;
  font-weight: normal;
}
body.nb-theme-material-light h4,
body.nb-theme-material-light .h4 {
  font-weight: normal;
}
body.nb-theme-material-light .btn {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.15) 45%, rgba(0, 0, 0, 0.15) 65%, rgba(255, 255, 255, 0.3));
  background-repeat: repeat-x;
  font-weight: bold;
  padding: 0.28rem 0.9rem;
}
body.nb-theme-material-light .btn + g-button {
  margin-left: 1rem;
}
body.nb-theme-material-light g-button + g-button,
body.nb-theme-material-light g-button + .btn {
  margin-left: 0.9rem;
}
body.nb-theme-material-light .btn.btn-sm {
  padding: 2px 4px;
}
body.nb-theme-material-light .btn + .btn,
body.nb-theme-material-light .btn-group + .btn-group {
  margin-left: 0.9rem;
}
body.nb-theme-material-light .btn-group .btn + .btn {
  margin-left: 0;
}
body.nb-theme-material-light .btn-circle {
  border-radius: 50%;
  padding: 5px 8px;
}
body.nb-theme-material-light .btn-outline-primary:hover {
  color: #fff !important;
}
body.nb-theme-material-light a.btn {
  color: #ffffff;
}
body.nb-theme-material-light a.btn:hover {
  color: #ffffff;
}
body.nb-theme-material-light .table th {
  text-align: center;
}
body.nb-theme-material-light .link {
  cursor: pointer;
}
body.nb-theme-material-light .bold {
  font-weight: bold;
}
body.nb-theme-material-light .cursor-pointer {
  cursor: pointer;
}
body.nb-theme-material-light .dropdown-item {
  cursor: pointer;
}
body.nb-theme-material-light label {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-top: 0.6rem;
}
@media only screen and (max-width: 480px) {
  body.nb-theme-material-light hr {
    margin: 0.5rem 0;
  }
  body.nb-theme-material-light h2,
  body.nb-theme-material-light .h2 {
    font-size: 1.5rem;
  }
  body.nb-theme-material-light h3,
  body.nb-theme-material-light .h3 {
    font-size: 1.3rem;
  }
}

.has-float-label {
  display: block;
  position: relative;
}

.has-float-label label,
.has-float-label > span {
  position: absolute;
  cursor: text;
  font-size: 75%;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  top: -0.5em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
}

.has-float-label label::after,
.has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  background: #fff;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.has-float-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: 150%;
  opacity: 0.5;
  top: 0.3em;
}

.input-group .has-float-label {
  display: table-cell;
}

.input-group .has-float-label .form-control {
  border-radius: 0.25rem;
}

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

/*variables */
.dialog-lg {
  max-width: 1200px;
  width: 100%;
}

.dialog-md {
  max-width: 900px;
  width: 100%;
}

.dialog-sm {
  max-width: 600px;
  width: 100%;
}

.p-datatable {
  font-size: 0.86rem;
}

.p-datatable .p-datatable-header {
  padding: 0.6rem;
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f2f2f2;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #007bff;
  color: #ffffff;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.8rem;
  color: #212529;
  background: #ffffff;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.6rem;
}

.p-dialog {
  font-size: 0.85rem;
}

.p-dialog .p-dialog-header {
  padding: 0.5rem;
  background-color: #ddf;
}

.p-dialog .p-dialog-footer {
  padding: 0.5rem;
}

.p-panel .p-panel-header {
  font-size: 1.2rem;
}

.p-selectbutton .p-button.p-highlight {
  background: #007bff;
  border-color: #007bff;
  color: #ffffff;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: 1px solid slategrey !important;
}

input[type=checkbox] {
  transform: scale(1.5) !important;
}

.date-picker {
  width: 100%;
}

.table-edit tr td {
  padding: 4px;
}

.table-edit tr td textarea,
.table-edit tr td input {
  border: none;
}

.filter label {
  margin-bottom: 0;
  display: block;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}
.rtl .filter label {
  text-align: right;
}
.rtl h2,
.rtl .h2 {
  text-align: right;
}
.rtl h3,
.rtl .h3 {
  text-align: right;
}
.rtl label {
  text-align: right;
}
.rtl .btn + .btn {
  margin-right: 16px;
}
.rtl tr > td {
  text-align: right;
}
.rtl .col {
  text-align: right;
}

.rtl .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item .ui-chkbox {
  margin: 0 0 0 0.5em;
}
.rtl .ui-dropdown-panel .ui-dropdown-item {
  text-align: right;
}

:root {
  --aqua: #7fdbff;
  --blue: #0074d9;
  --navy: #001f3f;
  --teal: #39cccc;
  --green: #2ecc40;
  --olive: #3d9970;
  --lime: #01ff70;
  --yellow: #ffdc00;
  --orange: #ff851b;
  --red: #ff4136;
  --fuchsia: #f012be;
  --purple: #b10dc9;
  --maroon: #85144b;
  --white: #ffffff;
  --silver: #dddddd;
  --gray: #aaaaaa;
  --black: #111111;
}

/* Backgrounds */
.bg-navy {
  background-color: var(--navy);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-aqua {
  background-color: var(--aqua);
}

.bg-teal {
  background-color: var(--teal);
}

.bg-olive {
  background-color: var(--olive);
}

.bg-green {
  background-color: var(--green);
}

.bg-lime {
  background-color: var(--lime);
}

.bg-yellow {
  background-color: var(--yellow);
}

.bg-orange {
  background-color: var(--orange);
}

.bg-red {
  background-color: var(--red);
}

.bg-fuchsia {
  background-color: var(--fuchsia);
}

.bg-purple {
  background-color: var(--purple);
}

.bg-maroon {
  background-color: var(--maroon);
}

.bg-white {
  background-color: var(--white);
}

.bg-gray {
  background-color: var(--gray);
}

.bg-silver {
  background-color: var(--silver);
}

.bg-black {
  background-color: var(--black);
}

/* Colors */
.navy {
  color: var(--navy);
}

.blue {
  color: var(--blue);
}

.aqua {
  color: var(--aqua);
}

.teal {
  color: var(--teal);
}

.olive {
  color: var(--olive);
}

.green {
  color: var(--green);
}

.lime {
  color: var(--lime);
}

.yellow {
  color: var(--yellow);
}

.orange {
  color: var(--orange);
}

.red {
  color: var(--red);
}

.fuchsia {
  color: var(--fuchsia);
}

.purple {
  color: var(--purple);
}

.maroon {
  color: var(--maroon);
}

.white {
  color: var(--white);
}

.silver {
  color: var(--silver);
}

.gray {
  color: var(--gray);
}

.black {
  color: var(--black);
}

/* Border colors 
   Use with another border utility that sets border-width and style 
   i.e .border { border-width: 1px); border-style: solid); }     
*/
.border--navy {
  border-color: var(--navy);
}

.border--blue {
  border-color: var(--blue);
}

.border--aqua {
  border-color: var(--aqua);
}

.border--teal {
  border-color: var(--teal);
}

.border--olive {
  border-color: var(--olive);
}

.border--green {
  border-color: var(--green);
}

.border--lime {
  border-color: var(--lime);
}

.border--yellow {
  border-color: var(--yellow);
}

.border--orange {
  border-color: var(--orange);
}

.border--red {
  border-color: var(--red);
}

.border--fuchsia {
  border-color: var(--fuchsia);
}

.border--purple {
  border-color: var(--purple);
}

.border--maroon {
  border-color: var(--maroon);
}

.border--white {
  border-color: var(--white);
}

.border--gray {
  border-color: var(--gray);
}

.border--silver {
  border-color: var(--silver);
}

.border--black {
  border-color: var(--black);
}

/* Fills for SVG */
.fill-navy {
  fill: var(--navy);
}

.fill-blue {
  fill: var(--blue);
}

.fill-aqua {
  fill: var(--aqua);
}

.fill-teal {
  fill: var(--teal);
}

.fill-olive {
  fill: var(--olive);
}

.fill-green {
  fill: var(--green);
}

.fill-lime {
  fill: var(--lime);
}

.fill-yellow {
  fill: var(--yellow);
}

.fill-orange {
  fill: var(--orange);
}

.fill-red {
  fill: var(--red);
}

.fill-fuchsia {
  fill: var(--fuchsia);
}

.fill-purple {
  fill: var(--purple);
}

.fill-maroon {
  fill: var(--maroon);
}

.fill-white {
  fill: var(--white);
}

.fill-gray {
  fill: var(--gray);
}

.fill-silver {
  fill: var(--silver);
}

.fill-black {
  fill: var(--black);
}

/* Strokes for SVG */
.stroke-navy {
  stroke: var(--navy);
}

.stroke-blue {
  stroke: var(--blue);
}

.stroke-aqua {
  stroke: var(--aqua);
}

.stroke-teal {
  stroke: var(--teal);
}

.stroke-olive {
  stroke: var(--olive);
}

.stroke-green {
  stroke: var(--green);
}

.stroke-lime {
  stroke: var(--lime);
}

.stroke-yellow {
  stroke: var(--yellow);
}

.stroke-orange {
  stroke: var(--orange);
}

.stroke-red {
  stroke: var(--red);
}

.stroke-fuchsia {
  stroke: var(--fuchsia);
}

.stroke-purple {
  stroke: var(--purple);
}

.stroke-maroon {
  stroke: var(--maroon);
}

.stroke-white {
  stroke: var(--white);
}

.stroke-gray {
  stroke: var(--gray);
}

.stroke-silver {
  stroke: var(--silver);
}

.stroke-black {
  stroke: var(--black);
}

/*variables */
body.nb-theme-material-light .primary nb-layout-header,
body.nb-theme-material-light .primary nb-layout-footer > nav.fixed {
  background-color: #0064c9 !important;
}
body.nb-theme-material-light .primary nb-menu .menu-item .menu-icon {
  color: #0064c9;
  width: 30px;
}
body.nb-theme-material-light .primary h2,
body.nb-theme-material-light .primary .h2,
body.nb-theme-material-light .primary .primary {
  color: #0064c9 !important;
}
body.nb-theme-material-light .primary .bg-primary,
body.nb-theme-material-light .primary .ui-table .ui-table-thead > tr > th {
  color: #ffffff !important;
  background-color: #0064c9 !important;
}
body.nb-theme-material-light .primary .ui-selectbutton .ui-button.ui-state-active {
  background-color: #0064c9;
  border-color: #0064c9;
}
body.nb-theme-material-light .primary .btn-primary {
  background-color: #0064c9;
  border-color: #0064c9;
}
body.nb-theme-material-light .primary .btn-outline-primary {
  color: #0064c9;
  border-color: #0064c9;
}
body.nb-theme-material-light .primary .btn-outline-primary.active {
  color: #ffffff;
  background-color: #0064c9;
  border-color: #0064c9;
}

body.nb-theme-material-light .blue nb-layout-header,
body.nb-theme-material-light .blue nb-layout-footer > nav.fixed {
  background-color: #0064c9 !important;
}
body.nb-theme-material-light .blue nb-menu .menu-item .menu-icon {
  color: #0064c9;
  width: 30px;
}
body.nb-theme-material-light .blue h2,
body.nb-theme-material-light .blue .h2,
body.nb-theme-material-light .blue .primary {
  color: #0064c9 !important;
}
body.nb-theme-material-light .blue .bg-primary,
body.nb-theme-material-light .blue .ui-table .ui-table-thead > tr > th {
  color: #ffffff !important;
  background-color: #0064c9 !important;
}
body.nb-theme-material-light .blue .ui-selectbutton .ui-button.ui-state-active {
  background-color: #0064c9;
  border-color: #0064c9;
}
body.nb-theme-material-light .blue .btn-primary {
  background-color: #0064c9;
  border-color: #0064c9;
}
body.nb-theme-material-light .blue .btn-outline-primary {
  color: #0064c9;
  border-color: #0064c9;
}
body.nb-theme-material-light .blue .btn-outline-primary.active {
  color: #ffffff;
  background-color: #0064c9;
  border-color: #0064c9;
}

body.nb-theme-material-light .fuchsia nb-layout-header,
body.nb-theme-material-light .fuchsia nb-layout-footer > nav.fixed {
  background-color: #f012be !important;
}
body.nb-theme-material-light .fuchsia nb-menu .menu-item .menu-icon {
  color: #f012be;
  width: 30px;
}
body.nb-theme-material-light .fuchsia h2,
body.nb-theme-material-light .fuchsia .h2,
body.nb-theme-material-light .fuchsia .primary {
  color: #f012be !important;
}
body.nb-theme-material-light .fuchsia .bg-primary,
body.nb-theme-material-light .fuchsia .ui-table .ui-table-thead > tr > th {
  color: #ffffff !important;
  background-color: #f012be !important;
}
body.nb-theme-material-light .fuchsia .ui-selectbutton .ui-button.ui-state-active {
  background-color: #f012be;
  border-color: #f012be;
}
body.nb-theme-material-light .fuchsia .btn-primary {
  background-color: #f012be;
  border-color: #f012be;
}
body.nb-theme-material-light .fuchsia .btn-outline-primary {
  color: #f012be;
  border-color: #f012be;
}
body.nb-theme-material-light .fuchsia .btn-outline-primary.active {
  color: #ffffff;
  background-color: #f012be;
  border-color: #f012be;
}

body.nb-theme-material-light .olive nb-layout-header,
body.nb-theme-material-light .olive nb-layout-footer > nav.fixed {
  background-color: #3d9970 !important;
}
body.nb-theme-material-light .olive nb-menu .menu-item .menu-icon {
  color: #3d9970;
  width: 30px;
}
body.nb-theme-material-light .olive h2,
body.nb-theme-material-light .olive .h2,
body.nb-theme-material-light .olive .primary {
  color: #3d9970 !important;
}
body.nb-theme-material-light .olive .bg-primary,
body.nb-theme-material-light .olive .ui-table .ui-table-thead > tr > th {
  color: #ffffff !important;
  background-color: #3d9970 !important;
}
body.nb-theme-material-light .olive .ui-selectbutton .ui-button.ui-state-active {
  background-color: #3d9970;
  border-color: #3d9970;
}
body.nb-theme-material-light .olive .btn-primary {
  background-color: #3d9970;
  border-color: #3d9970;
}
body.nb-theme-material-light .olive .btn-outline-primary {
  color: #3d9970;
  border-color: #3d9970;
}
body.nb-theme-material-light .olive .btn-outline-primary.active {
  color: #ffffff;
  background-color: #3d9970;
  border-color: #3d9970;
}

body.nb-theme-material-light .purple nb-layout-header,
body.nb-theme-material-light .purple nb-layout-footer > nav.fixed {
  background-color: #b10dc9 !important;
}
body.nb-theme-material-light .purple nb-menu .menu-item .menu-icon {
  color: #b10dc9;
  width: 30px;
}
body.nb-theme-material-light .purple h2,
body.nb-theme-material-light .purple .h2,
body.nb-theme-material-light .purple .primary {
  color: #b10dc9 !important;
}
body.nb-theme-material-light .purple .bg-primary,
body.nb-theme-material-light .purple .ui-table .ui-table-thead > tr > th {
  color: #ffffff !important;
  background-color: #b10dc9 !important;
}
body.nb-theme-material-light .purple .ui-selectbutton .ui-button.ui-state-active {
  background-color: #b10dc9;
  border-color: #b10dc9;
}
body.nb-theme-material-light .purple .btn-primary {
  background-color: #b10dc9;
  border-color: #b10dc9;
}
body.nb-theme-material-light .purple .btn-outline-primary {
  color: #b10dc9;
  border-color: #b10dc9;
}
body.nb-theme-material-light .purple .btn-outline-primary.active {
  color: #ffffff;
  background-color: #b10dc9;
  border-color: #b10dc9;
}

body.nb-theme-material-light .orange nb-layout-header,
body.nb-theme-material-light .orange nb-layout-footer > nav.fixed {
  background-color: #ff851b !important;
}
body.nb-theme-material-light .orange nb-menu .menu-item .menu-icon {
  color: #ff851b;
  width: 30px;
}
body.nb-theme-material-light .orange h2,
body.nb-theme-material-light .orange .h2,
body.nb-theme-material-light .orange .primary {
  color: #ff851b !important;
}
body.nb-theme-material-light .orange .bg-primary,
body.nb-theme-material-light .orange .ui-table .ui-table-thead > tr > th {
  color: #ffffff !important;
  background-color: #ff851b !important;
}
body.nb-theme-material-light .orange .ui-selectbutton .ui-button.ui-state-active {
  background-color: #ff851b;
  border-color: #ff851b;
}
body.nb-theme-material-light .orange .btn-primary {
  background-color: #ff851b;
  border-color: #ff851b;
}
body.nb-theme-material-light .orange .btn-outline-primary {
  color: #ff851b;
  border-color: #ff851b;
}
body.nb-theme-material-light .orange .btn-outline-primary.active {
  color: #ffffff;
  background-color: #ff851b;
  border-color: #ff851b;
}

body.nb-theme-material-light .maroon nb-layout-header,
body.nb-theme-material-light .maroon nb-layout-footer > nav.fixed {
  background-color: #85144b !important;
}
body.nb-theme-material-light .maroon nb-menu .menu-item .menu-icon {
  color: #85144b;
  width: 30px;
}
body.nb-theme-material-light .maroon h2,
body.nb-theme-material-light .maroon .h2,
body.nb-theme-material-light .maroon .primary {
  color: #85144b !important;
}
body.nb-theme-material-light .maroon .bg-primary,
body.nb-theme-material-light .maroon .ui-table .ui-table-thead > tr > th {
  color: #ffffff !important;
  background-color: #85144b !important;
}
body.nb-theme-material-light .maroon .ui-selectbutton .ui-button.ui-state-active {
  background-color: #85144b;
  border-color: #85144b;
}
body.nb-theme-material-light .maroon .btn-primary {
  background-color: #85144b;
  border-color: #85144b;
}
body.nb-theme-material-light .maroon .btn-outline-primary {
  color: #85144b;
  border-color: #85144b;
}
body.nb-theme-material-light .maroon .btn-outline-primary.active {
  color: #ffffff;
  background-color: #85144b;
  border-color: #85144b;
}