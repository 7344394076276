@import "_variables.scss";

$themes: (
    primary: $primary,
    blue: $blue,
    fuchsia: $fuchsia,
    olive: $olive,
    purple: $purple,
    orange: $orange,
    maroon: $maroon
);
// @each ( $name : $value )
@each $name, $value in $themes {
    body.nb-theme-material-light .#{$name} {
        nb-layout-header,
        nb-layout-footer > nav.fixed {
            background-color: $value !important;
        }

        nb-menu .menu-item .menu-icon {
            color: $value;
            width: 30px;
        }

        h2,
        .h2,
        .primary {
            color: $value !important;
        }

        .bg-primary,
        .ui-table .ui-table-thead > tr > th {
            color: $white !important;
            background-color: $value !important;
        }

        .ui-selectbutton .ui-button.ui-state-active {
            background-color: $value;
            border-color: $value;
        }

        .btn-primary {
            background-color: $value;
            border-color: $value;
        }
        .btn-outline-primary {
            color: $value;
            // background-color: #fff;
            border-color: $value;
        }
        .btn-outline-primary.active {
            color: #ffffff;
            background-color: $value;
            border-color: $value;
        }
    }
}
