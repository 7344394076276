
/*Loaders */
.loader {
    position: relative;
  }
  .loader:before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ccc;
    opacity: 0.6;
  }
  .loader:after {
    border-right: 4px solid #2E91BD;
    border-top: 4px solid #2E91BD;
    border-left: 4px solid #1584de;
    border-bottom: 4px solid #bbbbeb;
    z-index: 2;
    content: "";
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    -webkit-transition: all .75s ease 0s;
    transition: all .75s ease 0s;
    border-radius: 100%;
    -webkit-animation: standard .75s infinite linear;
    animation: standard .75s infinite linear;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
  }
  .loader.no-overlay:before {
    content: none;
    display: none;
  }
  @-webkit-keyframes standard {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes standard {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  /* Loader ends here. */