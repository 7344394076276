body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: 1px solid slategrey !important;
}

input[type="checkbox"] {
  transform: scale(1.5) !important;
}

.date-picker {
  width: 100%;
}

.table-edit tr td {
  padding: 4px;
}
.table-edit tr td textarea,
.table-edit tr td input {
  border: none;
}

.filter label {
  margin-bottom: 0;
  display: block;
}

.ltr {
  direction: ltr;
}
